import moment from 'moment/min/moment-with-locales';


class abbonamento
{
   
codice?:string;
stato?:string;
tipo?:string;     //01 venditore   02 completo
descrizione?:string;

ival:Date ;
fineval:Date ;


datains?: Date;
opecreaz?: String;
opecreazemail?: String;

dataultmod?: Date;
opeultmod?: string;
opeultmodemail?: string;
importo?:number;


to_obj() {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }
}

constructor(pcodice:string,ptipo:string)
{
this.codice=pcodice;
this.tipo=ptipo;
this.ival = new Date();
if (this.tipo=='01')
this.fineval = moment().add(7, 'days').toDate();
else if (this.tipo=='02')
  this.fineval = moment().add(30, 'years').toDate();
else  this.fineval = moment().toDate();
};

fromJson(json:any)
{
  this.codice=json.codice;
  this.stato=json.stato;
  this.tipo=json.tipo;
  this.descrizione=json.descrizione;
  
 this.ival=json.ival;
 this.fineval=json.fineval;
  
  this.datains=json.datains;
  this.opecreaz=json.opecreaz;
  this.opecreazemail=json.opecreazemail;
  
  this.dataultmod=json.dataultmod;
  this.opeultmod=json.opeultmod;
  this.opeultmodemail=json.opeultmodemail;
  this.importo=json.importo;

  this.to_obj();
};

}

export default abbonamento;