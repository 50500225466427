import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";
import immobile from "../obj/immobile";
import ricImmobile from "../obj/ricImmobile";





export default class restImmobile {

  private static myInstance: restImmobile;


  constructor() {


  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'immobile';



  static getInstance() {
    if (restImmobile.myInstance == null) {
      restImmobile.myInstance = new restImmobile();
      // Util.myInstance.inizializza();

    }
    return restImmobile.myInstance;
  }



  getImmobili = async () => {

    

    

    const user = getAuth().currentUser;
    var token = await user?.getIdToken();

    

    var json: any;
    var json2: string;
    json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();
      
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      json2 = await response.text();
      


      if (json2) {
        json = JSON.parse(json2);
        json.esito = 'OK';

      }
      else json = { esito: "KO2" };


    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';


      return json;

    }

  }




  getImmobilibyIdope = async (popeid: string, prighe: number) => {



    const user = getAuth().currentUser;
    var token = await user?.getIdToken();



    var json: any;
    var json2: string;
    json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/idope/' + popeid + "?righe=" + prighe, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      )
      json2 = await response.text();

      if (json2) {
        json = JSON.parse(json2);
        json.esito = 'OK';
        let lista: immobile[] = [];
        json.forEach((ele: immobile) => {
          let d2: immobile = Object.assign(new immobile(), ele);
          lista.push(d2);
        });
        json = lista;
      }
      else json = { esito: "KO2" };


    } catch (error) {
      console.error(error);
      json = { esito: "KO" };
    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';


      return json;

    }

  }




  getImmobiliforMap = async (centro: Object,raggio:number) => {



    const user = getAuth().currentUser;
    var token = await user?.getIdToken();
    let risposta = {};
    let lst: immobile[] = [];

    var json: any;
    var json2: string;
    let esitox = { esito: "KO", descrizione: "Errore generico in getImmobiliforMap" };
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();

      

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/map/lista', {
        method: 'POST',
        headers: {
           Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
        body: JSON.stringify(
          {raggio:raggio,          
          centro:centro
          })
      }
      )
      json = response.json();
      esitox = json.esito;
      if (json.esito && json.esito.esito == "OK") {

        json.dati.lista.forEach((ele: immobile) => {
          let d2: immobile = Object.assign(new immobile(), ele);
          lst.push(d2);
        });

      }
      else if (!json.esito)
        esitox = { esito: "KO2", descrizione: "Errore getImmobiliforMap" };



    } catch (error) {
      console.error(error);
      esitox = { esito: "KO2", descrizione: (error as Error).message };
    } finally {
      risposta = { esito: esitox, dati: { lista: lst } }



      return json;

    }

  }





   caricaImmobilibyaddress = async (paraRic:ricImmobile) => {

    
    console.log("caricaImmobili", "stocaricando",  "Inizio2",paraRic);
    return new Promise<object>  ((resolve, reject) => {
      if (paraRic==undefined || paraRic==null) resolve ({dati: {listaimoobili:[]}});
      let para: Object[] = [];
      let paddr: google.maps.places.PlaceResult | null|undefined=paraRic.place;
    
        console.log("caricaImmobili", "stocaricando",  "Avviato timeout", new Date());
        console.log("caricaImmobilibyaddress", paddr);
        if (paddr==undefined || paddr==null) 
        {
          console.log("caricaImmobilibyaddress", paddr,"esco");
          resolve ({dati: {listaimoobili:[]}});
        }
        if (paddr?.types?.includes("route")) {
          paddr.address_components?.forEach(element => {
            if (element.types.includes("route")) {
              para.push({addr:{ tipo: 'route', ln: element.long_name, sn: element.short_name }});
            }
            else if (element.types.includes("locality")) {
              para.push({addr:{ tipo: 'locality', ln: element.long_name, sn: element.short_name }});
            }
          });
        }
        else if (paddr?.types?.includes("locality")) {
          paddr.address_components?.forEach(element => {
            if (element.types.includes("locality")) {
              para.push({addr:{ tipo: 'locality', ln: element.long_name, sn: element.short_name }});
            }
          });
        }
        else if (paddr?.types?.includes("administrative_area_level_2")) {
          paddr.address_components?.forEach(element => {
            if (element.types.includes("administrative_area_level_2")) {
              para.push({addr:{ tipo: 'administrative_area_level_2', ln: element.long_name, sn: element.short_name} });
            }
          });
        }
        else if (paddr?.types?.includes("administrative_area_level_1")) {
          paddr.address_components?.forEach(element => {
            if (element.types.includes("administrative_area_level_1")) {
              para.push({addr:{ tipo: 'administrative_area_level_1', ln: element.long_name, sn: element.short_name }});
            }
          });
        }

        console.log("caricaImmobilibyaddress3", para);
             this.getImmobilibyAddress(para).then((out: any) => {
                  resolve( out);
        });
    }
  ) ;
      }
    
  


  getImmobilibyAddress = async (para: Object) => {



    const user = getAuth().currentUser;
    var token = await user?.getIdToken();
    let risposta = {};
    let lst: immobile[] = [];

    var json: any;
    var json2: string;
    let esitox = { esito: "KO", descrizione: "Errore generico in getImmobilibyAddress" };
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();

      

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/map/byaddress', {
        method: 'POST',
        headers: {
           Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
        body: JSON.stringify(para)
      }
      )
      json = response.json();
      esitox = json.esito;
      if (json.esito && json.esito.esito == "OK") {

        json.dati.lista.forEach((ele: immobile) => {
          let d2: immobile = Object.assign(new immobile(), ele);
          d2.to_obj();
          lst.push(d2);
        });

      }
      else if (!json.esito)
        esitox = { esito: "KO2", descrizione: "Errore getImmobilibyAddress" };



    } catch (error) {
      console.error(error);
      esitox = { esito: "KO2", descrizione: (error as Error).message };
    } finally {
      risposta = { esito: esitox, dati: { lista: lst } }



      return json;

    }

  }


  ricercaImmobili = async (para: ricImmobile) => {

console.log("RicercaImoobili****************");
console.log(para);
    const user = getAuth().currentUser;
    var token = await user?.getIdToken();
    let risposta = {};
    let lst: immobile[] = [];

    var jsonRR: any;
    
    let esitox = { esito: "KO", descrizione: "Errore generico in ricercaImmobile" };
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();

     if ((!para || !para.place || !para.indirizzo ) && !para.poligono && para.raggio==0)
      {
        esitox = { esito: "KO7", descrizione: "Nessuna zona per la ricerca indicata" };
        return { esito: esitox, dati: { lista: [] } }
      }

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/ricerca/v01', {
        method: 'POST',
        headers: {
           Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        },
        body: JSON.stringify(para)
      }
      )
      jsonRR = await response.json();
      esitox = jsonRR.esito;


      if (jsonRR.esito && jsonRR.esito.esito == "OK") {

        jsonRR.dati.listaimmobili.forEach((ele: immobile) => {
          let d2: immobile = Object.assign(new immobile(), ele);
          d2.to_obj();
          lst.push(d2);
        });

      }
      else if (!jsonRR.esito)
        esitox = { esito: "KO2", descrizione: "Errore ricercaImmobile" };



    } catch (error) {
      console.error(error);
      esitox = { esito: "KO2", descrizione: (error as Error).message };
    } finally {
      risposta = { esito: esitox, dati: { listaimmobili: lst } }

console.log("*********************************************",risposta);

      

    }
    return risposta;
  }




  getImmobile = async (gid: string) => {
    const user = getAuth().currentUser;
    var token = await user?.getIdToken();


    try {

      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' + gid, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      const json = await response.json();
      if (response.status >= 300) {
        console.log("restImmo", "getImmobile", "--", response.status, json);
        throw 'Errore ' + JSON.stringify(json);
      }
      return json;
    } catch (error) {
      console.error(error);
      return "KO";
    } finally {
      console.log("finito getImmobile");
    }
  }


  aggiungi = async (pimmo: immobile) => {
    
    var vimmo = { ...pimmo };
    var out: object;
    out = { esito: "KO" };
    var json!: immobile;
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/'
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(pimmo)
        });
      json = await response.json();

      if (response.status >= 300) {
        console.log("restImmo", "aggiungi", "--", response.status, json);
        out = { esito: 'KO' }
        throw 'Errore ' + JSON.stringify(json);
      }
      else {
        let d2: immobile = Object.assign(new immobile(), json);
        out = { esito: 'OK', dati: d2 };
      }
    } catch (error) {
      console.error(error);
      out = { esito: 'KO - ' + error };
    } finally {
      if (!json) out = { esito: "KO" };

      return out;
    }
  }

  modifica = async (pimmo: immobile, notifica: boolean) => {
    // console.log("Modifica",ppers,ppers.nome);
    var out: object;
    out = { esito: "KO" };
    var json!: immobile;
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();

      var p = { ...pimmo };
      delete p._id;

      const s = this.restUrlServer + '/' + this.restApp + '/' + pimmo._id + "?notifica=" + notifica;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });


      json = await response.json();

      if (response.status >= 300) {
        console.log("restImmo", "modifica", "--", response.status, json);
        out = { esito: "KO" };
        throw 'Errore ' + JSON.stringify(json);
      }
      else {
        let d2: immobile = Object.assign(new immobile(), json);
        out = { esito: 'OK', dati: d2 };
      }
    } catch (error) {
      console.error(error);
      out = { esito: "KO - " + error };
    } finally {
      if (!json) out = { esito: "KO" };

      return out;
    }


  }

}