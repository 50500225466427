import moment from "moment";
import datiInit from "../util/datiInit";
import datiGen from "../util/datiGen";

import { getAuth } from "firebase/auth";
import ProfiloUtente from "../obj/ProfiloUtente.js";
import abbonamento from "../obj/abbonamento";



export default class restProfiloUtente {

  private static myInstance: restProfiloUtente;
  private caricaprofiloincorso: number;

  constructor() {

    this.caricaprofiloincorso = 0;
  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'profiliutente';


  static getInstance() {
    if (restProfiloUtente.myInstance == null) {
      restProfiloUtente.myInstance = new restProfiloUtente();
      // Util.myInstance.inizializza();

    }
    return restProfiloUtente.myInstance;
  }

  prova() {
    console.log('restProfiloUtente', 'prova');
  }




  getListaProfiliUtente = async () => {

    //console.log('RESTCALL','ProfiliUTENTE');

    /*non test per evitare riciclo, è testata sul backend
       if (!datiGen.getInstance().possoaccedere('PROFILIUTENTE'))
       throw 'Non sei abilitato a svolgere la funzione PROFILIUTENTE';
   */

    const user = getAuth().currentUser;
    var token = await user?.getIdToken();


    var json: any;
    json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token: string | undefined = await user?.getIdToken();

      const response = await fetch(this.restUrlServer + '/' + this.restApp, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      json = await response.json();
      //console.log('restcall','getListaProfiliUtente',json);


    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';

      this.caricaprofiloincorso = 0;
      return json;

    }

  }


  getProfiloUtente = async (pidutente: string) => {
    //console.log('rest getProfiloutente', pidutente);
    if (this.caricaprofiloincorso > 0) return;

    const user = getAuth().currentUser;
    var token: string | undefined = await user?.getIdToken();

    this.caricaprofiloincorso = 1;
    var json: any;
    json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token: string | undefined = await user?.getIdToken();
     // console.log('rest getProfiloutente chiamata:', this.restUrlServer + '/' + this.restApp + "/profilout/" + pidutente);
      const response = await fetch(this.restUrlServer + '/' + this.restApp + "/profilout/" + pidutente, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      //json = await response.json();
      json = await response.text();
      //console.log('getProfiloutente', json);
      if (json)
        json = JSON.parse(json) as ProfiloUtente;
      else json = { esito: "KO" };
    

    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';

      this.caricaprofiloincorso = 0;
      return json;

    }

  }

  aggiungi = async (profilo: ProfiloUtente) => {

    //console.log("restProfiloUtente", "aggiungi", profilo);
    var vprofilo = { ...profilo };
    var json = { esito: "KO" };

    try {


      const user = getAuth().currentUser;
      var token = await user?.getIdToken();
      //console.log('restprofiloutente', this.restUrlServer + '/' + this.restApp);
      delete vprofilo._id;

      const response = await fetch(this.restUrlServer + '/' + this.restApp
        , {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(vprofilo)
        });
      //console.log('restprofiloutente1', 'XXXXXXXXXXXXXXX', response);
     // console.log('restprofiloutente2', 'XXXXXXXXXXXXXXX', vprofilo);

      if (!response.ok) {
        console.log("restProfiloUtente", "aggiungi", "--", response.status, json);
        json.esito = 'KO'

        // throw 'Errore ' + JSON.stringify(json);
      }
      else {
        json = await response.json();
        json.esito = 'OK'

      }
    } catch (error) {
      console.error('restProfiloutente', error);
      json.esito = 'KO'
    } finally {
      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';
      return JSON.stringify(json);
    }
  }

  modifica = async (profilo: ProfiloUtente) => {
    console.log("Modifica", "profilo", profilo);
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();

      var p = { ...profilo };
      const pid = p._id;

      delete p._id;
      delete p.esito;

      const s = this.restUrlServer + '/' + this.restApp + '/' + pid;
      console.log("Modifica", "s", s,JSON.stringify(p));
      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify(p)
        });
        console.log("Modifica", "0000");
        const json2 = await response.text();
        console.log("Modifica", "0011",json2); 
      let  json = await JSON.parse(json2);
      console.log("Modifica", "profilo", JSON.stringify(json));
      if (json && !json.esito)
      {
        datiGen.getInstance().aggiornaUtenteCollegato(json);
      }
      else   if (!json)
      {
        json= { esito: 'ko', des: "Errore 718" };  
      }
   
      console.log("Modifica", "11111");
      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }

  }
  sendMsgTokenRegistration = async (msgtoken: string) => {
  //  console.log("sendMsgTokenRegistration", msgtoken);
    try {
      const user = getAuth().currentUser;
      if (!user) {
        //   console.log('sendMsgTokenRegistration','utente non definito esco');
        return;

      }
      var token = await user.getIdToken();
     // console.log("sendMsgTokenRegistration", msgtoken, user.uid, user.displayName);


      const s = this.restUrlServer + '/' + this.restApp + '/' + user.uid + '/tokenMsgRegistration?token=' + msgtoken;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },

        });

      const json = await response.json();
    //  console.log("sendMsgTokenRegistration", "risposta: ", response, json);

      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }
  }


  attivaAbbonamento = async (codiceAbbonamento: string) => {

    try {
      const user = getAuth().currentUser;
      if (!user) {
        //   console.log('sendMsgTokenRegistration','utente non definito esco');
        return;

      }
    
      var token = await user.getIdToken();
      


      const s = this.restUrlServer + '/' + this.restApp + '/' + user.uid + '/attivaAbbonamento?codiceAbbonamento=' + codiceAbbonamento;

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },

        });

      const json = await response.json();
      
if (json.esito=="OK")
  {

    datiGen.getInstance().getUtenteCollegato()?.attivaAbbonamento(codiceAbbonamento);
  }
  else
  {
    console.log("attivaAbbonamento", "Attenzione:",json); 
  }
      return json;

    } catch (error) {
      console.error(error);
    } finally {


    }
  }


  addAbbonamento = async (pabb: abbonamento) => {
    
    try {
      const user = getAuth().currentUser;
      if (!user) {
        //   console.log('sendMsgTokenRegistration','utente non definito esco');
        return;

      }
      var token = await user.getIdToken();
   //   console.log("addAbbonamento rest", "inizio: ", pabb);
      const s = this.restUrlServer + '/' + this.restApp + '/' + user.uid + '/abbonamento/add';

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },

          body: JSON.stringify(pabb)
        });

      const json = await response.json();
 //     console.log("addAbbonamento", "risposta: ", response, json);

      return json;

    } catch (error) {
      console.error("AddAbbonamento"," ERRORE ",error);
      return   { esito: "KO",des:error };
    } finally {


    }
  }


  addRicerca = async (pric: object) => {
    
    try {
      const user = getAuth().currentUser;
      if (!user) {
        //   console.log('sendMsgTokenRegistration','utente non definito esco');
        return;

      }
      var token = await user.getIdToken();
      //console.log("addRicerca rest", "inizio: ", pric);
      const s = this.restUrlServer + '/' + this.restApp + '/' + user.uid + '/ricerca/add';

      const response = await fetch(s
        , {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },

          body: JSON.stringify(pric)
        });

      const json = await response.json();
 //     console.log("addAbbonamento", "risposta: ", response, json);

      return json;

    } catch (error) {
      console.error("addRicerca"," ERRORE ",error);
      return   { esito: "KO",des:error };
    } finally {


    }
  }

  getProvaNotifica = async () => {
//    console.log('rest getProvaNotifica');
    if (this.caricaprofiloincorso > 0) return;

    const user = getAuth().currentUser;
    var token: string | undefined = await user?.getIdToken();


    var json: any;
    json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token: string | undefined = await user?.getIdToken();
      //console.log('rest getProvaNotifica chiamata:', this.restUrlServer + '/' + this.restApp + "/provanotifica");
      const response = await fetch(this.restUrlServer + '/' + this.restApp + "/provanotifica", {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      json = await response.text();
      //console.log('provanotifica', json);
      if (json)
        json = JSON.parse(json);
      else json = { esito: "KO" };


    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO" };
      if (!json.esito) json.esito = 'KO';


      return json;

    }

  }


  getNotifichebyIdope = async (popeid:string,prighe:number) => {
    
    

    const user = getAuth().currentUser;
    var token = await user?.getIdToken();

    

    var json:any;
    var json2:string;
    json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();
    
      const response= await fetch(this.restUrlServer + '/' + this.restApp + '/'+popeid+"/notifiche?righe="+prighe , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
      json = await response.json();
      json.esito={esito:"OK"};
     // console.log("getImmobilibyidope", json);

    } catch (error) {
      console.error(error);
      json = { esito: "KO" };
    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';


      return json;

    }

  }


}