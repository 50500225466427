
import React, { useEffect, useState, useRef } from 'react';
import '../stili/App.css';
import '../stili/App2.css';
import '../stili/App01.css';
import { Button } from 'primereact/button';



import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

import { Image } from 'primereact/image';
import { DataScroller } from 'primereact/datascroller';
import home from '../img/home.svg';
import cercaaff from '../img/cercaaff.svg';
import comprare from '../img/comprare.svg';
import affittare from '../img/affittare.svg';
import vendere from '../img/vendere.svg';
import quadImmobile from '../img/quadImmobile.svg';
import busta from '../img/busta.svg';

import imageEsempio from '../img/imageEsempio.png';
import { useTranslation } from 'react-i18next';

//firebase 



import { useNavigate, useLocation, Link, redirect } from "react-router-dom";
import datiGen from '../util/datiGen';





const FooterApp = (props) => {
  const [putente, setPutente] = useState();


  const [notif, setNotif] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const ds = useRef(null);

  let timeoutID = -1;




  const Cerca = () => {
    if (datiGen.getInstance().getUtenteCollegato().abbonamentoAttivo(["02"]) > 0) {
      navigate("/cercaMappa", { state: {} });
    }
    else {
      navigate("/comprare", { state: {} });
    }


  }





  return (

    <div className='FooterContainer' >
          {1>4 &&
          <>
          <span style={{ color: "#FFFFFF" }}>
        --{location.pathname}--
        </span>
        <span className='FooterText'>ZZZZZZZZZ</span>
        </>
          }
      {location && location.pathname && location.pathname != '/homeUtente' &&
        <div className='FooterElement' onClick={() => { navigate("/homeUtente", { state: { funzione: "Insert" } }); }}>
          <Image src={home} height='25' width='25' className='FooterImage'

          />
          <span className='FooterText'>Home</span>
        </div>
      }
      {location && (!location.pathname || ( location.pathname && location.pathname != '/dettImmobile')) &&
        <div className='FooterElement' onClick={() => { navigate("/dettImmobile", { state: { funzione: "Insert" } }); }}>
          <Image src={vendere} height='25' width='25' className='FooterImage'

          />
          <span className='FooterText'>Vendere</span>
        </div>
      }
      <div className='FooterElement'>
        <Image src={affittare} height='25' width='25' className='FooterImage'
        />
        <span className='FooterText'>Affittare</span>
      </div>
      {location && (!location.pathname ||  location.pathname != '/cercaMappa') &&
        <div className='FooterElement'>
          <Image src={comprare} height='25' width='25' className='FooterImage'
            onClick={() => { Cerca(); }}
          />

          <span className='FooterText'>Comprare</span>
        </div>
      }
      <div className='FooterElement'>
        <Image src={cercaaff} height='25' width='25' className='FooterImage'
        />
        <span className='FooterText'>Cercare in Affitto</span>
      </div>
    </div>







  );
}



export default FooterApp;
