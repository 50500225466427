
import ProfiloUtente from "../obj/ProfiloUtente";
import restConf from "../restcall/restConf";
import restProfiloUtente from "../restcall/restProfiloUtente";
import datiInit from "./datiInit";
import moment from 'moment/min/moment-with-locales';








export default class datiGen {

  private static myInstance: datiGen;
  private caricaprofiloincorso: number;
  private colori: Map<String, Object>;
  private authattiva: number;
  private utentecollegato?: ProfiloUtente;
  private utente: any;
  private ISPWA:number;

  constructor() {



    this.caricaprofiloincorso = 0;
    
    this.colori = new Map();
    this.authattiva = 0;
    this.ISPWA=0;



    //utente
    //profiloutente
  }





  static getInstance() {
    if (datiGen.myInstance == null) {

      datiGen.myInstance = new datiGen();
      // Util.myInstance.inizializza();
      datiGen.myInstance.inizializza().then(() => console.log('Inizializing'));


    }



    return datiGen.myInstance;
  }


  inizializza() {
    return new Promise((resolve, reject) => {
      
      // this.caricaruolifunz();
      //  this.caricaruolifunzGruppoSQ();
      datiInit.getInstance().inizializza();

       
    })
  }






async aggiornaUtenteCollegato(ut:ProfiloUtente)
{
  this.utentecollegato = Object.assign(new ProfiloUtente(), ut); 
  this.utentecollegato.to_obj();
  }




  async caricaProfiloUtente(putente: any, utreg: ProfiloUtente) {
    console.log('caricaProfiloUtente', 'inizio',putente,utreg);
    if (putente && (this.caricaprofiloincorso === putente.uid || this.caricaprofiloincorso === 1)) {
      return;
    }

    if (putente)
      this.caricaprofiloincorso = putente.uid;
    else
      this.caricaprofiloincorso = 1;
    let trovato = 0;
    var json: ProfiloUtente;
    var json2: ProfiloUtente;

    if (putente) {

      try {

        json = await restProfiloUtente.getInstance().getProfiloUtente(putente.uid);
        if (!json.idutente) {
          trovato = 0;

        }
        else {
      //    console.log('aaaak123');
          this.utentecollegato = Object.assign(new ProfiloUtente(), json); 
           this.utentecollegato.to_obj();
       
          ;
          trovato = 1;
        }
      } catch (error) {
        console.log('Error Aggiungi profilo utente', error);

        trovato = 0;
      }

      if (trovato === 0) {

        console.log("carico prfilo utente nuovo",putente.email,utreg.email);
        this.utentecollegato = new ProfiloUtente();
        this.utentecollegato.idutente = putente.uid;
        this.utentecollegato.email = putente.email?putente.email:utreg.email;
        this.utentecollegato.cognome = utreg && utreg.cognome ? utreg.cognome : '';
        this.utentecollegato.nome = utreg && utreg.nome ? utreg.nome : '';
        this.utentecollegato.displayName = this.utentecollegato.cognome + " " + this.utentecollegato.nome;
        //  this.utentecollegato.ruoli.push({ idsoc: "##", gruppi: ["LOGIN"] });
        this.utentecollegato.telefono = utreg && utreg.telefono ? utreg.telefono : putente.telefono;
        this.utentecollegato.authuser = putente;
        if (utreg && utreg.dateAccPriv)
          this.utentecollegato.dateAccPriv = utreg.dateAccPriv;


        try {
        //  console.log('aaaak125');
          await restProfiloUtente.getInstance().aggiungi(this.utentecollegato);
        //  console.log('aaaak125');
          json2 = await restProfiloUtente.getInstance().getProfiloUtente(putente.uid);
      //    console.log('aaaak125');
          //     console.log('caricaProfiloUtente','8',json2 );
          this.utentecollegato = Object.assign(new ProfiloUtente(), json2); 
          this.utentecollegato.to_obj();
      //    console.log('aaaak125',this.utentecollegato.abbonamenti);
          //    console.log('caricaProfiloUtente','9');

        } catch (error) {
          console.error(error);
        }
      }

    }
    else {  //se logout per mettere nullo

      this.utentecollegato = putente;

    }

    //  console.log('caricaProfiloUtente','quasi fine' );

    this.caricaprofiloincorso = 0;



  }




  getUtente() {
    return this.utente;
  }

  getUtenteCollegato() {
    
    //let xx:ProfiloUtente= Object.assign(new ProfiloUtente(), this.utentecollegato);  
    return this.utentecollegato;
  }

  getAuthAttiva() {
    return this.authattiva;
  }
  setAuthAttiva(val: number) {
    this.authattiva = val;
  }

  isPWA()
  {
        return this.ISPWA;
  }
setIsPWA(val: number)
{
    this.ISPWA = val;

}



  async sendMsgTokenRegistration(token: string) {
    console.log("datigen", "sendMsgTokenRegistration", token);
    await restProfiloUtente.getInstance().sendMsgTokenRegistration(token);
  }



  async setUtente(user: any, utreg: ProfiloUtente) {
    return new Promise((resolve, reject) => {
    //  console.log('setUtente:', user, utreg);
      this.utente = user;
      this.caricaProfiloUtente(this.utente, utreg).then(() => {
        resolve(this.utentecollegato);

      }).catch((error) => {
        console.log('setUtente error:', error);
        reject(error);
      });
    });

  }
  getNomeUtenteCollegato() {
    var nu = "xx";
    //console.log(this.utentecollegato);
    if (this.utentecollegato) nu = this.utentecollegato.displayName;
    return nu;
  }






  creaID = (prefisso: string) => {
    const minCeiled = Math.ceil(0);
    const maxFloored = Math.floor(100);
    let k = Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);


    let cid = (prefisso ? prefisso : "I") + moment().format("YY") + "-" + new Date().getTime() + "-" + k.toString().padStart(3, '0');
    return cid;


  }

















}