
import React, { useEffect, useState, useRef } from 'react';
import logo from '../img/logo_wevee.svg';
import installa from '../img/installa.svg';
import '../stili/App.css';
import '../stili/App01.css';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';


import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IosShareIcon from '@mui/icons-material/IosShare';
import MenuIcon from '@mui/icons-material/Menu';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

//firebase 
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo, getApps, getApp } from "firebase/auth";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";



import { useTranslation } from 'react-i18next';

//navigation
import { useNavigate, Link, useLocation, useSearchParams, Outlet,useBlocker } from "react-router-dom";
import datiGen from '../util/datiGen';
import { AlignHorizontalCenter } from '@mui/icons-material';
import restProfiloUtente from '../restcall/restProfiloUtente';
import elenchi from '../util/elenchi';
import ProfiloUtente from '../obj/ProfiloUtente';

import moment from 'moment/min/moment-with-locales';
import FooterApp from '../comp/FooterApp';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


let deferredPrompt;

const firebaseConfig = {
  apiKey: "AIzaSyDUK-IO1zDk4DzDJezap3M21nX_CGHEXcE",
  authDomain: "weveering.firebaseapp.com",
  projectId: "weveering",
  storageBucket: "weveering.appspot.com",
  messagingSenderId: "766523325469",
  appId: "1:766523325469:web:7e05cac41d20f64e3d1af4",
  measurementId: "G-GSKJBGLJYE"
};



const App = () => {
  const [installable, setInstallable] = useState(false);

  const app = initializeApp(firebaseConfig);

  const analytics = getAnalytics(app);
  const auth = getAuth(app);


  const navigate = useNavigate();
  const mostrainstallazione = true;
  const [azione, setAzione] = useState("Reg"); //Reg Login //Logged  //Logging //Registering
  const toast = useRef(null);
  const [flagPriv, setFlagPriv] = useState(false);
  const [dateAccPriv, setDateAccPriv] = useState();
  const provider = new GoogleAuthProvider();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [mostraInstall, setMostraInstall] = useState(false);

  const [bloccaNav, setBloccaNav] = useState(false);
  const { t, i18n } = useTranslation();



  
  
  let messaging = null;


  const showNotifica = (titolo, testo) => {
    toast.current.show({ severity: 'info', summary: titolo, detail: testo });
  };
  const show = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo });
  };
  const showInfo = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo });
  };
  const showAlert = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };
  const showError = (testo) => {
    toast.current.show({ severity: 'error', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };






  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
     bloccaNav &&
      currentLocation.pathname !== nextLocation.pathname
  );
  
  const proceed = () => {
    
    blocker.proceed();
    setBloccaNav(false);
  
  }
  
  useEffect(() => {
    console.log('D101');
   if (blocker.state == 'blocked') {
      confirm1();
     // console.log('confirm1');
    }
  }, [blocker.state]);
  
  const confirm1 = () => {
    
    confirmDialog({
      message: 'Non tutte le modifiche sono state salvate, vuoi uscire comunque?',
      header: 'Attenzione',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: (() => { if (blocker.state == 'blocked') proceed() }),
      reject: (() => { if (blocker.state == 'blocked') {blocker.reset(); }}),
    });
  };

  useEffect(() => {
 

//disable back button
window.addEventListener('popstate', function(event) {
  navigate("/",{replace:true});
      });


    addLocale('it',
      {
        "accept": "Si",
        "addRule": "Aggiungi regola",
        "am": "AM",
        "apply": "Applica",
        "cancel": "Annulla",
        "choose": "Scegli",
        "chooseDate": "Seleziona Data",
        "chooseMonth": "Seleziona Mese",
        "chooseYear": "Seleziona Anno",
        "clear": "Cancella tutto",
        "completed": "Completato",
        "contains": "Contiene",
        "custom": "Personalizzato",
        "dateAfter": "La data è successiva",
        "dateBefore": "La data è precedente",
        "dateFormat": "dd/mm/yy",
        "dateIs": "La data è",
        "dateIsNot": "La data non è",
        "dayNames": [
          "Domenica",
          "Lunedi",
          "Martedì",
          "Mercoledì",
          "Giovedì",
          "Venerdì",
          "Sabato"
        ],
        "dayNamesMin": [
          "Do",
          "Lu",
          "Ma",
          "Me",
          "Gi",
          "Ve",
          "Sa"
        ],
        "dayNamesShort": [
          "Dom",
          "Lun",
          "Mar",
          "Mer",
          "Gio",
          "Ven",
          "Sab"
        ],
        "emptyFilterMessage": "Nessuna opzione disponibile",
        "emptyMessage": "Nessun risultato trovato",
        "emptySearchMessage": "Nessun risultato trovato",
        "emptySelectionMessage": "Nessun elemento selezionato",
        "endsWith": "Finisce con",
        "equals": "Equivale",
        "fileSizeTypes": [
          "B",
          "KB",
          "MB",
          "GB",
          "TB",
          "PB",
          "EB",
          "ZB",
          "YB"
        ],
        "filter": "Filtro",
        "firstDayOfWeek": 1,
        "gt": "Maggiore di",
        "gte": "Maggiore o uguale a",
        "lt": "Minore di",
        "lte": "Minore o uguale a",
        "matchAll": "Abbina tutto",
        "matchAny": "Abbina alcuni",
        "medium": "Medio",
        "monthNames": [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre"
        ],
        "monthNamesShort": [
          "Gen",
          "Feb",
          "Mar",
          "Apr",
          "Mag",
          "Giu",
          "Lug",
          "Ago",
          "Set",
          "Ott",
          "Nov",
          "Dic"
        ],
        "nextDecade": "Decade successiva",
        "nextHour": "Ora successiva",
        "nextMinute": "Minuto successivo",
        "nextMonth": "Mese successivo",
        "nextSecond": "Secondo successivo",
        "nextYear": "Anno successivo",
        "noFilter": "Senza Filtro",
        "notContains": "Non contiene",
        "notEquals": "Non uguale",
        "now": "Ora attuale",
        "passwordPrompt": "Inserisci la password",
        "pending": "In corso",
        "pm": "PM",
        "prevDecade": "Decade precedente",
        "prevHour": "Ora precedente",
        "prevMinute": "Minuto precedente",
        "prevMonth": "Mese precedente",
        "prevSecond": "Secondo precedente",
        "prevYear": "Anno precedente",
        "reject": "No",
        "removeRule": "Rimuovi regola",
        "searchMessage": "{0} risultati disponibili",
        "selectionMessage": "{0} elementi selezionati",
        "showMonthAfterYear": false,
        "startsWith": "Inizia con",
        "strong": "Forte",
        "today": "Oggi",
        "upload": "Carica",
        "weak": "Debole",
        "weekHeader": "Sett",
        "aria": {
          "cancelEdit": "Annulla modifica",
          "close": "Chiudi",
          "collapseLabel": "Riduci",
          "collapseRow": "Riduci riga",
          "editRow": "Modifica riga",
          "expandLabel": "Espandi",
          "expandRow": "Espandi riga",
          "falseLabel": "Falso",
          "filterConstraint": "Costante di filtro",
          "filterOperator": "Operatore di filtro",
          "firstPageLabel": "Prima pagina",
          "gridView": "Griglia",
          "hideFilterMenu": "Nascondi Menu filtri",
          "jumpToPageDropdownLabel": "Vai alla Dropdown delle pagine",
          "jumpToPageInputLabel": "Vai all'Input delle pagine",
          "lastPageLabel": "Ultima pagina",
          "listView": "Lista",
          "moveAllToSource": "Muovi tutto alla sorgente",
          "moveAllToTarget": "Muovi tutto all'elemento",
          "moveBottom": "Vai in fondo",
          "moveDown": "Vai sotto",
          "moveTop": "Vai in cima",
          "moveToSource": "Vai alla sorgente",
          "moveToTarget": "Vai all'elemento",
          "moveUp": "Vai sopra",
          "navigation": "Naviga",
          "next": "Successivo",
          "nextPageLabel": "Pagina successiva",
          "nullLabel": "Non selezionato",
          "otpLabel": "Inserisci il carattere della password monouso {0}",
          "pageLabel": "Pagina {page}",
          "passwordHide": "Nascondi password",
          "passwordShow": "Mostra password",
          "previous": "Precedente",
          "previousPageLabel": "Pagina precedente",
          "rotateLeft": "Ruota a sinistra",
          "rotateRight": "Ruota a destra",
          "rowsPerPageLabel": "Elementi per pagina",
          "saveEdit": "Salva modifica",
          "scrollTop": "Torna sù",
          "selectAll": "Seleziona tutti gli elementi",
          "selectLabel": "Seleziona",
          "selectRow": "Seleziona riga",
          "showFilterMenu": "Mostra Menu filtri",
          "slide": "Scorri",
          "slideNumber": "{slideNumber}",
          "star": "1 stella",
          "stars": "{star} stelle",
          "trueLabel": "Vero",
          "unselectAll": "Deseleziona tutti gli elementi",
          "unselectLabel": "Deseleziona",
          "unselectRow": "Deseleziona riga",
          "zoomImage": "Zoom Immagine",
          "zoomIn": "Ingrandisci",
          "zoomOut": "Riduci"
        }
      }



    );

    //console.log("location", location, window.location);
    if (app && auth.currentUser && datiGen.getInstance().getUtenteCollegato()) {
      //console.log("use effect  00980", "SI");
      setAzione("Logged");
      setTimeout(() => { navigate("/homeUtente",); }, 500);
    }
    else {

      //console.log("use effect  00980", "NO");
    }
    /*if (searchParams.get('pagina')!=null ) {
       rigirachiamate();
       return;
     }*/


    setTimeout(() => {
      //console.log("use effect  00980", "Timeout");

      //se richiamo pagina e c'è già login. Ho messo time out per dare tempo di fare autenticazione
      if (auth.currentUser && auth.currentUser.displayName && !datiGen.getInstance().getUtenteCollegato()) {

        datiGen.getInstance().setUtente(auth.currentUser).then((ut) => {
          console.log("To Home utente 3", azione);
          setAzione("Logged");
          if (ut && ut.lingua) {
            //console.log("Cambio lingua", azione, ut.lingua.toLowerCase());
            changeLanguage(ut.lingua.toLowerCase());
            locale(ut.lingua.toLowerCase());
            moment.locale(ut.lingua);
         //   console.log("zz213", ut);
          }
          elenchi.getInstance();
          inviatokenMsg();

          if (searchParams.get('pagina') != null && window.location.pathname !== '/HomeUtente') {
            rigirachiamate();
            return;
          }
          else {

            setTimeout(() => { if (auth.currentUser && datiGen.getInstance().getUtenteCollegato()) navigate("/homeUtente"); }, 500);
          }
        });
      }
   /*   else
        console.log("use effect  00980", "ELSE", auth.currentUser, datiGen.getInstance().getUtenteCollegato());*/
    }, 700);




    //PER INSTALLAZIONE
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });




  
    if (isInStandaloneMode() || !isIos())
      messaging = getMessaging(app);


    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("onAuthStateChanged App", azione, auth, user, location);

      if (user)
        elenchi.getInstance();

      if (location.pathname == '/') {

        if (user && user.providerData[0].providerId == 'google.com' && azione == 'Reg') {
          setAzione("Logged");
          console.log('onAuthStateChanged', 1, user);
          //Non autentico perchè passa in dati utente prima di creare profilo
          // setTimeout(()=> navigate("/homeUtente"),250);
        }
        /*  else if (user && user.providerData[0].providerId == 'google.com') {
        //Pre google login non autentico perchè ho già fatto setutente dentro signinpopup
               console.log('onAuthStateChanged', 2, user);
            autentica(user);
            
          }*/
        /* else if (user && user.displayName)    //se non ha display name quando loggo con mail significa che non ha finito processo di registrazione e quindi attendo a caricare/registrare profilo
        {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
  
          // ...
          autentica(user);
  
  
        } */
        else {
          //  console.log("onAuthStateChanged", "else");
          // User is signed out
          // ...
          console.log("User log out");
        }

      }
      else {
        console.log("onAuthStateChanged App", "Altra location", azione, auth, user, location.pathname);

      }



datiGen.getInstance().setIsPWA(isPWA());



      return () => unsubscribe();
    });


//per sistemare bug scroll ios
document.body.style.overflow = "hidden"


  }, []);



  /*

  //valutare se da ripristinare --> attenzione blocca outlet quando parte useblocker
  useEffect(() => {
    //parte ogni volta che entri nella pagina
    console.log('D103');
    console.log("app.jsx", "use effect location", location.pathname, azione);
    if (location.pathname = "/" && azione == "Logging")
      setAzione("Login");
    if (location.pathname = "/" && azione == "Registering")
      setAzione("Reg");
  }, [location]);
*/

  useEffect(() => {
    console.log('D104');
    //return ()=>unsubscribe_msg();
    console.log("App", "azione", azione);
  }, [azione]);







  useEffect(() => {

    let unsubscribe_msg;
    if (isInStandaloneMode() || !isIos()) {
      unsubscribe_msg = onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        showNotifica(payload.notification.title, payload.notification.body);
        // ...
      });
    }

    if (!isPWA()) {

      if (!auth.currentUser)
        setMostraInstall(true);


    }


    return () => { if (isInStandaloneMode() || !isIos()) unsubscribe_msg() };
  }, []);

  function isPWA() {
    return window.navigator.standalone == true || // iOS PWA Standalone
      document.referrer.includes('android-app://') || // Android Trusted Web App
      ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      ) // Chrome PWA (supporting fullscreen, standalone, minimal-ui)
  }


  const inviatokenMsg = () => {
    if (!("Notification" in window || !isSupported || (!isInStandaloneMode() && isIos()))) {
      // Check if the browser supports notifications
      console.log("inviatokenMsg", "This browser does not support desktop notification");
    }
    else {
      if (!messaging) messaging = getMessaging();
      console.log('Inviatokenmsg inizio');
      getToken(messaging, { vapidKey: 'BP29rV2Mh-Wxf1hd1pDTS5amoT1ejYbTq2_yxsKSyw6bVUug-dib7hb3qT3ViLaIyG8POnUJMn8teIQ4t3kpw40' }).then((tk) => {
        if (tk) {
          // Send the token to your server and update the UI if necessary
          datiGen.getInstance().sendMsgTokenRegistration(tk);
          //     alert("token tk assegnato:"+tk);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
          console.log('Requesting permission...');
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              // alert('Notification permission granted.');
              datiGen.getInstance().sendMsgTokenRegistration(tk);
            }
          });
        }

      })
        .catch((errore) => {
          // alert("token errore " + errore);
          console.log('home.js', ' messagind: idtoken:', errore);
        });
    }
  }


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const rigirachiamate = (async () => {

    console.log("rigirachiamate", "searchParams ", searchParams, searchParams.toString());
    var par2 = searchParams.get('pagina');
    console.log("rigirachiamate", "searchParams ", "pagina", par2);
    if (par2 == "EsitoPagamentoOK") {
      var par = searchParams.get('nrOrdine');
      setTimeout(() => {
        restProfiloUtente.getInstance().attivaAbbonamento(par).then((out) => {
          console.log("App.jsx", "Esito Attiva Abbonamento:", out);
          navigate("/dettImmobile", { replace: true, state: { funzione: "Insert" } });

        });
      }, 300);
    }

    console.log("rigirachiamate", par2, (par2 === "EsitoPagamentoHome"), (par2 == "EsitoPagamentoHome"))
    if (par2 === "EsitoPagamentoHome") {
      console.log("rigirachiamate", "App.jsx", "EsitoPagamentoHome1");
      setTimeout(() => {
        console.log("rigirachiamate§", "App.jsx", "EsitoPagamentoHome2");
        var par = searchParams.get('nrOrdine');
        restProfiloUtente.getInstance().attivaAbbonamento(par).then((out) => {
          //  console.log("rigirachiamate","AttivaAbb1",datiGen.getInstance().getUtenteCollegato()?.abbonamenti);
          navigate("/homeUtente");
        });
      }, 500);
    }

    //  console.log("rigirachiamate", par2, (par2 === "EsitoPagamentoRicerca"), (par2 == "EsitoPagamentoRicerca"))
    if (par2 === "EsitoPagamentoRicerca") {
      //  console.log("rigirachiamate", "App.jsx", "EsitoPagamentoRicerca1");
      setTimeout(() => {
        //  console.log("rigirachiamate§", "App.jsx", "EsitoPagamentoRicerca2");
        var par = searchParams.get('nrOrdine');
        restProfiloUtente.getInstance().attivaAbbonamento(par).then((out) => {
          //  console.log("rigirachiamate","AttivaAbb2",datiGen.getInstance().getUtenteCollegato()?.abbonamenti);
          navigate("/cercaMappa");
        });
      }, 500);
    }


  })



/*
      const autentica = (async (user) => {
      console.log("Auth Autentica App.js", azione, user);
      //Variabile azione non valorizzata correttamente, forse perchè listener
  
      if (datiGen.getInstance().getAuthAttiva() > 0) {
        console.log("Esco Autentica", datiGen.getInstance().getAuthAttiva());
        return;
      }
      if (datiGen.getInstance().getUtenteCollegato())
        return;
      datiGen.getInstance().setAuthAttiva(1);
      // setUtente(datiGen.getInstance().getUtenteCollegato());
      datiGen.getInstance().setUtente(user).then((ut) => {
        setAzione("Logged");
        if (ut && ut.lingua) {
          console.log("Cambio lingua", azione, ut.lingua.toLowerCase());
          changeLanguage(ut.lingua.toLowerCase());
          moment.locale(ut.lingua);
          locale(ut.lingua.toLowerCase);
        }
        //console.log("fine set utente", azione, ut);
      })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
          console.log("Auth Create Error", error);
          showError(error.code + "-" + error.message);
        }).finally(() => {
          datiGen.getInstance().setAuthAttiva(0);
        });
  
  
    });
  */


  const isMobile = () => {
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return isMobile;
  }



  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|mac/.test(userAgent);
  };

  const isMozilla = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /firefox|fxios/.test(userAgent);
  };

  // check if the device is in standalone mode
  const isInStandaloneMode = () => {

    return (
      "standalone" in window.navigator &&
      window.navigator.standalone
    );
  };




  const handleInstallClick = (e) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  };




  return (

    <div className="App">
      <Toast ref={toast} position='center' />
<ConfirmDialog />


      <header className="App-header">
        {(azione == 'Reg' || azione == "Login") &&
          <img src={logo} className="App-logo animated flip" alt="logo" />
        }



      </header>

      {1 > 5 &&
        <span style={{ color: "#FFFFFF" }}>
          ------------------{azione}-------------------------
        </span>
      }
      {1 > 3 &&
        <span style={{ color: "#FFFFFF" }}>
          agent:{window.navigator.userAgent}
        </span>}
      {(azione == 'Reg') &&
        <>
          <p>
            {t('Home.HaiAccount')}<span className='turchese' onClick={() => { setAzione("Login"); }}>Login</span>
          </p>
        </>
      }
      {(azione == 'Login') &&
        <p>
          {t('Home.NonHaiAccount')} <span className='turchese' onClick={() => { setAzione("Reg"); }} >{t('Home.Registrati')}</span>
        </p>
      }


      {(azione == 'Reg' || azione == 'Login') &&
        <div className='flexContainer tabresp' >
          <Button rounded className="bottoneHome-p-button" size='large' onClick={
            () => {
              if (azione && azione == 'Login') {
                setAzione("Logging");
                navigate("/datiUtente", { state: { azione: "Logging", tipo: 'tel' } });

              }
              else if (flagPriv) {
                setAzione("Registering");
                navigate("/datiUtente", { state: { azione: "Registering", tipo: 'tel', dtAccettaPriv: dateAccPriv } });

              }
              else {
                showAlert("Accettare le condizioni di utilizzo e privacy");
              }
            }} >
            <i className="pi pi-mobile" ></i>
            <span className='bottoneHome-label'> {t("Home.Cellulare")}</span>
          </Button>
          <Button rounded className="bottoneHome-p-button" size='large' onClick={
            () => {
              if (azione && azione == 'Login') {
                setAzione("Logging");
                navigate("/datiUtente", { state: { azione: "Logging", tipo: 'email' } });

              }
              else if (flagPriv) {
                setAzione("Registering");
                navigate("/datiUtente", { state: { azione: "Registering", tipo: 'email', dtAccettaPriv: dateAccPriv } });

              }
              else {
                showAlert("Accettare le condizioni di utilizzo e privacy");
              }
            }}
          >
            <i className="pi pi-inbox" ></i>
            <span className='bottoneHome-label'>E-mail         </span>
          </Button>

          <Button rounded className="bottoneHome-p-button" size='large'
            onClick={
              () => {

                if (azione && (azione == 'Reg') && !flagPriv) {
                  showAlert("Accettare le condizioni di utilizzo e privacy");

                }
                else {
                  signInWithPopup(auth, provider)
                    .then((result) => {
                      console.log("SIGNINWITHPOPUP");
                      // This gives you a Google Access Token. You can use it to access the Google API.
                      const credential = GoogleAuthProvider.credentialFromResult(result);
                      const token = credential.accessToken;
                      // The signed-in user info.
                      const auser = result.user;
                      // IdP data available using getAdditionalUserInfo(result)
                      // ...
                      const aIuser = getAdditionalUserInfo(result);
                      auser.profilo = aIuser;
                      //console.log("Login 3", auser);

                      if (azione === 'Reg') {
                        console.log("INIZIO REG");
                        var utmp = new Object();
                        utmp.idutente = auser.uid;
                        utmp.displayName = auser.displayName;
                        utmp.email = auser.email;
                        utmp.cognome = auser.profilo.profile.family_name;
                        utmp.nome = auser.profilo.profile.given_name;
                        utmp.telefono = auser.phoneNumber ? auser.phoneNumber : "";
                        utmp.profilo = auser.profilo;
                        console.log("REGISTERING QUa QUA")
                        setAzione("Registering");
                        // setAzione("Logged");
                        console.log(utmp);
                        console.log({ ...utmp });
                        navigate("/datiUtente", { state: { azione: "Registering", tipo: 'Google', dtAccettaPriv: dateAccPriv, putente: { ...utmp } } });

                      }
                      else {
                        datiGen.getInstance().setUtente(auser).then((ut) => {
                          console.log("To Home utente 2", azione, ut);
                          if (ut && ut.lingua) {
                            console.log("Cambio lingua", azione, ut.lingua.toLowerCase());
                            changeLanguage(ut.lingua.toLowerCase());
                            moment.locale(ut.lingua);
                            locale(ut.lingua.toLowerCase());
                            inviatokenMsg();
                            setAzione("Logged");
                            setTimeout(() => navigate("/homeUtente"), 500);

                          }

                        });
                      }
                    }).catch((error) => {
                      // Handle Errors here.
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      // The email of the user's account used.
                      const email = error.customData.email;
                      // The AuthCredential type that was used.
                      const credential = GoogleAuthProvider.credentialFromError(error);
                      // ...
                    });

                }
              }}
          >
            <i className="pi pi-google"></i>
            <span className='bottoneHome-label'>Google</span>
          </Button>
          <Button rounded className="bottoneHome-p-button" size='large' disabled>
            <i className="pi pi-apple"></i>
            <span className='bottoneHome-label'>Apple</span>
          </Button>
          <Button rounded className="bottoneHome-p-button" size='large' disabled>
            <i className="pi pi-facebook"></i>
            <span className='bottoneHome-label'>Facebook</span>
          </Button>
        </div>
      }
      {azione === 'Reg' &&
        <div>


          <Checkbox inputId="Priv" name="priv" checked={flagPriv}
            onChange={e => { setFlagPriv(e.checked); setDateAccPriv(new Date()); }}
          ></Checkbox>

          <label htmlFor="Priv" className='testoHome' > {t('Home.Accetta')}<span className='turchese'>{t('Home.Privacy')}
          </span></label>

          <p>

            {t('Home.DatiNonPubblicati')}
          </p>
        </div>
      }
 <Dialog visible={mostraInstall} style={{ width: '90vw', textAlign: 'center', borderRadius: '20px', border: '1px solid #46c0b5', maxWidth: '600px' }} onHide={() => { if (!mostraInstall) return; setMostraInstall(false); }}>
        <div className='pannelloIstruzioni'  >
          <p className="m-0">

            Per poter utilizzare tutte le funzionalità di Weveering è necessario installare l'app
          </p>



          {installable && mostrainstallazione &&
            <div style={{ width: "100%", textAlign: 'center' }}>
              <Button rounded className="bottoneHome-p-button" size='small' onClick={() => { setMostraInstall(false); handleInstallClick(); }} >
                <img src={installa} style={{ verticalAlign: 'middle', width: '3rem' }} />
                <span className='bottoneHome-label'> Installa</span>
              </Button>
            </div>
          }

          {!installable && !isInStandaloneMode() && !isMobile() && isMozilla() == true &&

            <div className='pannelloIstruzioni'  >
              <br />
              <p className="m-0">
                Per installare l'app<br />
                utilizzare Chrome o Edge<br />
                Con Firefox non è possibile installare le app.
              </p>
              <div style={{ width: "100%", textAlign: 'center' }}>
                <Button rounded className="bottoneHome-p-button" style={{ textAlign: 'center' }} size='small' onClick={() => { setMostraInstall(false); }} >
                  <span className='bottoneHome-label'> Chiudi</span>
                </Button>
              </div>
            </div>
          }


          {!installable && mostrainstallazione && !isInStandaloneMode() && !isIos() && !isMozilla() &&
            <div style={{ width: "100%", textAlign: 'center' }}>
              <Button rounded className="bottoneHome-p-button" size='small' onClick={() => { setMostraInstall(false); handleInstallClick(); }} >
                <img src={installa} style={{ verticalAlign: 'middle', width: '3rem' }} />
                <span className='bottoneHome-label'> Installa</span>
              </Button>
            </div>

          }

          {!installable && !isInStandaloneMode() && isMobile() && isMozilla() == true &&
            <div className='pannelloIstruzioni'  >
              <p style={{ textAlign: 'center', margin: '10px', padding: '10px' }} >
                Per installare l'app<br />
                Seleziona il menu <i className="pi pi-ellipsis-v" style={{ fontSize: '1rem' }}></i>e scegli <b>Aggiungi a Home</b>
              </p>
            </div>
          }


          {!installable && mostrainstallazione && !isInStandaloneMode() && isIos() && !isMozilla() &&

            <div class='pannelloIstruzioni'  >
              <p style={{ textAlign: 'center', margin: '10px', padding: '10px' }} >
                Per installare l'app<br />
                Seleziona il menu <b>Condividi</b> <IosShareIcon /> <br />
                e scegli <b>Aggiung alla schermata home</b>

              </p>
              <p>
                <a href='/istr1.mp4'>Guarda come</a>
              </p>
            </div>
          }

          {!installable && mostrainstallazione && !isInStandaloneMode() && isIos() && isMozilla() &&

            <div class='pannelloIstruzioni'  >
              <p style={{ textAlign: 'center', margin: '10px', padding: '10px' }} >
                Per installare l'app<br />
                Seleziona il menu <MenuIcon />
                e scegli <b>Condividi</b> <i className="pi pi-upload" style={{ fontSize: '1rem' }}></i> <br />
                e <b>Aggiung alla schermata home</b>

              </p>
              <p>
                <a href='/istr1.mp4'>Guarda come</a>
              </p>
            </div>
          }
        </div>
      </Dialog>
      
      
      <Outlet context={{azione:[azione, setAzione],bloccaNav:[bloccaNav,setBloccaNav]}} />
      

      {datiGen.getInstance().getUtenteCollegato() &&
        <FooterApp />
      }

    </div>
  );
}



export default App;
