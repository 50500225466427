import moment from "moment";
import datiInit from "../util/datiInit";
import { getAuth } from "firebase/auth";




export default class restConf {
  private static myInstance :restConf;
  
  
  constructor() {


  }

  restUrlServer = datiInit.getInstance().getRestUrlServer();

  restApp = 'conf';
  

  static getInstance() {
    if (restConf.myInstance == null) {
      restConf.myInstance = new restConf();
      // Util.myInstance.inizializza();

    }
    return restConf.myInstance;
  }



  getConfs = async () => {

    console.log("1111");

    console.log("getConfs");

    const user = getAuth().currentUser;
    var token = await user?.getIdToken();

    console.log("2222");

    var json:any;
    json = { esito: "KO" };
    try {
      const user = getAuth().currentUser;
      var token = await user?.getIdToken();
    //  console.log("getConfs", this.restUrlServer + '/' + this.restApp + '/' );
      const response = await fetch(this.restUrlServer + '/' + this.restApp + '/' , {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token
        }
      }
      );
       json = await response.text();
      console.log("getConfs", json);


      if (json) {
        json = JSON.parse(json);
        json.esito = 'OK';

      }
      else json = { esito: "KO2" };


    } catch (error) {
      console.error(error);

    } finally {

      if (!json) json = { esito: "KO3" };
      if (!json.esito) json.esito = 'KO';


      return json;

    }

  }



}