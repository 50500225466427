

export default class datiInit {

private restUrlServer:String;
private amb:String;
private static myInstance:datiInit;
  constructor() {

 
    this.restUrlServer= '#';
    this.amb='#';
    //utente
    //profiloutente
  }



  

  static getInstance() {
    if (datiInit.myInstance == null) {
      datiInit.myInstance = new datiInit();
      // Util.myInstance.inizializza();
      datiInit.myInstance.inizializza().then(() => console.log('Inizializing'));


    }



    return datiInit.myInstance;
  }


  inizializza() {
    return new Promise((resolve, reject) => {

     
      this.caricaamb();

    })
  }


  caricaamb()
{
  
  if (process.env.NODE_ENV === "development") {
    this.amb='DEV';
   this.restUrlServer= 'https://ws.weveering.com';
//this.restUrlServer= 'http://localhost:3000';
  }
 
  if (process.env.NODE_ENV === "production") {
    this.amb='PROD';
    this.restUrlServer= 'https://ws.weveering.com';
  }
  console.log('datiinit',this.amb,this.restUrlServer);
}

getRestUrlServer()
{
  return this.restUrlServer;
}

  getAmb()
  {
    return this.amb;
  }






}
