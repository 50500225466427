import moment from 'moment/min/moment-with-locales';
import abbonamento from './abbonamento';
import { User } from 'firebase/auth'


class ProfiloUtente {
  _id?: string;
  id: number;
  idutente: string;
  displayName: string;
  email: string;

  cognome: string;
  nome: string;
  telefono: string;
  msg_token = [];
  abbonamenti: abbonamento[];
  ricerche:Object[];
  dateAccPriv?: number | undefined;
  lingua: string;

  ival = new Date();
  fineval = moment().add(7, 'years').toDate();

  note = "";
  authuser : User | undefined;

  esito?: any;
  photoURL: string;

  nickname:string;



   preparaAbbonamento=(pcodice: string, ptipo: string) =>{
    var ab: abbonamento = new abbonamento(pcodice, ptipo);
    this.abbonamenti.push(ab);
  }


  attivaAbbonamento=(pnrord: string)=> {
    //console.log("ProfiloUtente", "Attiva Abbonamenti", this.abbonamenti);
    let matched:abbonamento|undefined = this.abbonamenti.find(abb => abb.codice==pnrord);
     matched!.stato="50";
   
   

    // console.log("Attiva Abbonamento",pnrord,matched,this.abbonamenti);
  }



  abbonamentoAttivo=(ptipo: string[]): number=> {
    // this.to_obj();
    let att: number = -1;

    // this.abbonamenti?.forEach((ele:abbonamento) => {
    //   let ele=Object.assign(new abbonamento(elex.codice!,elex.tipo!), elex); 
    //   ele.to_obj();

    if (this.abbonamenti.length > 0) {
      att = 0;

      for (const ele of this.abbonamenti) {
       // console.log("Profiloutente", "Abbonamento attitvo", ele.codice, ele.ival, ele.fineval);
        if (ptipo.indexOf(ele.tipo!) >= 0 && ele.stato == "50" && ele.ival.getTime() < new Date().getTime() && ele.fineval > new Date()) {
          att = 1;
          break;
        }
      };

    }

    return att;
  }




  constructor() {
    this._id = "";
    this.id = Date.now();
    this.idutente = "";
    this.displayName = "";
    this.email = "";

    this.cognome = "";
    this.nome = "";
    this.telefono = "";
    this.msg_token = [];
    this.abbonamenti = [];
    this.ricerche=[]
    this.lingua = "it";

    this.ival = new Date();
    this.fineval = moment().add(7, 'years').toDate();
    this.lingua = "IT"
    this.note = "";
    

    this.photoURL="";
    this.nickname="";
  }

  to_obj=()=> {
    if (this.ival && typeof this.ival == 'string') {
      this.ival = new Date(this.ival);
    }
    if (this.fineval && typeof this.fineval == 'string') {
      this.fineval = new Date(this.fineval);
    }


    for (let e: number = 0; e < this.abbonamenti!.length; e++) {
      var elex: abbonamento;
      elex = new abbonamento(this.abbonamenti![e].codice!, this.abbonamenti![e].tipo!);
      elex.fromJson(this.abbonamenti![e]);
      this.abbonamenti![e] = elex!;
    };
    console.log("to_obj==>", this.abbonamenti);
  }


  getAvatarUrl=():string=>
  {
    let s="";
    if (this.photoURL && this.photoURL.length>2)
      s=this.photoURL;
    else if (this.authuser?.photoURL )
    {
      s=this.authuser?.photoURL;
    }

return s;
  }


}






export default ProfiloUtente;