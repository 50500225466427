
import React, { useEffect, useState, useRef } from 'react';
import logo from '../img/logo_wevee.svg';
import profileIcon from '../img/profileIcon.svg';
import '../stili/App.css';
import '../stili/App01.css';
import { Button } from 'primereact/button';


import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import { Toast } from 'primereact/toast';

import MenuIcon from '@mui/icons-material/Menu';

import { useTranslation } from 'react-i18next';


import datiGen from '../util/datiGen';

import { useNavigate, useLocation, Link } from "react-router-dom";

import RiepNotifiche from '../comp/RiepNotifiche';
import FooterApp from '../comp/FooterApp';
import MieiDati from '../comp/MieiDati';







const DettProfilo = () => {


  const { t, i18n } = useTranslation();
  const [aggiornaT, setAggiornaT] = useState(0);
  const toast = useRef(null);
  


  const show = (testo) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: testo });
  };
  const showAlert = (testo) => {
    toast.current.show({ severity: 'warn', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };
  const showError = (testo) => {
    toast.current.show({ severity: 'error', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    

  }, []);

  useEffect(() => {
    

  }, [aggiornaT]);

const incAggiornaT=()=>
{
  setAggiornaT(aggiornaT+1);
}


  return (

    <div className="App">
      <Toast ref={toast} position='center' />
      <header className="App-header">
        <div className='topBar' >
        <Button rounded className='bottoneTopBar-p-button' >
            
        <MenuIcon  style={{height:"7vmin",fontSize:"3em"}} />
          </Button>
          <Link to={"/homeUtente"}>
            <img src={logo} className="App-logo3" alt="logo"
            />
          </Link>
          <Button rounded className='bottoneTopBar-p-button'
onClick={()=>{
  
navigate("/menuUtente");

}}          >
          <img src={profileIcon}  style={{height:"1.6rem",}}  />
          </Button>
          
        </div>


      </header>
      <div className='flexContainer' >

        <p key={aggiornaT}>
        {t('Benvenuto')}&nbsp;
          {datiGen.getInstance().getUtenteCollegato() &&
            <span className='turchese testoUpper'>{datiGen.getInstance().getUtenteCollegato().nome} {datiGen.getInstance().getUtenteCollegato().cognome}</span>
          }

        </p>

      </div>
      <MieiDati righe={200}  refresh={incAggiornaT}/>

    </div>






  );
}



export default DettProfilo;
