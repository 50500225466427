import restConf from "../restcall/restConf";
import datiGen from "./datiGen";


export default class elenchi {

  private static myInstance: elenchi;
  private conf: Map<String, Object>;
private elImmtipologia:[];




  constructor() {
    this.elImmtipologia =[];
    this.conf = new Map();
    
  }



  

  public static getInstance():elenchi {
    if (elenchi.myInstance == null) {
      elenchi.myInstance = new elenchi();
      // Util.myInstance.inizializza();
      elenchi.myInstance.inizializza();
      

    }



    return elenchi.myInstance;
  }


  inizializza() {
    
      this.caricaConf();
    
  }



  caricaConf =  () => {
    
      
    if (!this.conf)
      this.conf = new Map();
    

    restConf.getInstance().getConfs().then((json: Array<any>) => {
      json.forEach((ele) => {
        this.conf.set(ele.codice, ele);
      }

      )
      setTimeout(() => { this.caricaConf(); }, 1000 * 60 * 60 * 24);
      
      
    });
    
  


  }



 

  getConf = (p_cod_conf: String) => {
    console.log('getConf11--->','-----------',p_cod_conf,new Date().getTime(),this.conf.size);
      console.log('getConf2--->','-----------',p_cod_conf,this.conf,this.conf.size);
      console.log('getConf2--->',this.conf.keys());
   // const t = this.conf.get(p_cod_conf);
   const t = this.conf.get(p_cod_conf);
    console.log('getConf3--->','-----------',p_cod_conf,t);
    return t;
  }

  getElencoConf = (p_cod_conf: String) => {
    //console.log("125",p_cod_conf);
    //console.log('getElencoConf--->','-----------',this.conf);
    //console.log('this.conf.get(p_cod_conf).elencoVal--->','-----------',this.conf.get(p_cod_conf).elencoVal);
    let x: any = null;
    let out = null;
    if (this.conf)
      x = this.conf.get(p_cod_conf);
   
    if (x.elencoVal)
      out = x.elencoVal;


    return out;

  }

  getElencoMap = (p_cod_conf: String) => {
    //console.log("125",p_cod_conf);
    //console.log('getElencoConf--->','-----------',this.conf);
    //console.log('this.conf.get(p_cod_conf).elencoVal--->','-----------',this.conf.get(p_cod_conf).elencoVal);
    let x: any = null;
    let out = new Map();
    if (this.conf)
      x = this.conf.get(p_cod_conf);
    
    if (x.elencoVal)
      {       
       x.elencoVal.forEach((ele: { label: any; value: any; })   => {
        out.set(ele.value,ele.label);
        
      });
      }
      
    return out;

  }




  public getElImmtipologia(): Array<Object> {
    this.elImmtipologia =  this.getElencoConf('IMM_TIPOLOGIA');
    return this.elImmtipologia;
  }

 public getElenco(cat:String):Array<Object>  {
    const eltmp = this.getElencoConf(cat);
return eltmp;
}


}