import { v4 as uuid } from 'uuid'
import immagine from './immagine';


type geoPoint =
  {
    type: string,
    coordinates: number[]
  }


class immobile {
  _id?: string;
  codice?: string;
  tipo?: string;
  titolo?: string;
  descrizione?: string;
  indirizzo?: string;
  prezzo?: number;
  proprietario?:
    {
      cognome?: string;
      nome?: string;
      codicefiscale?: string;
    }
  tipoFabbricato?: string;
  annoCostruzione?: number;
  pianoStabile?: number;
  portineria?: boolean;
  ascensore?: boolean;
  giardinoCondominiale?: boolean;
  pianoImmobile?: number;
  superficie?: number;
  livelli?: number;
  camere?: number;
  altrestanze?: number;
  cucina?: string;
  bagni?: number;
  ripostiglio?: boolean;
  balconi?: number;
  terrazzo?: boolean;
  giardino?: boolean;
  mansarda?: boolean;
  taverna?: boolean;
  cantina?: boolean;
  box?: string;
  spesecondominiali?: number;
  riscaldamento?: string;
  ariacondizionata?: boolean;
  videocitofono?: boolean;
  barriere?: boolean;

  idope?: string;
  emailope?: string;

  datains?: Date;
  opecreaz?: String;
  opecreazemail?: String;

  dataultmod?: Date;
  opeultmod?: string;
  opeultmodemail?: string;

  stato?: string;   //01 inserito
  immagini?: immagine[];


  place?: google.maps.places.PlaceResult;
  posizione?: geoPoint;

  claseEnergetica?: string;
  indicePrestEnerg?: number;


  /*
  settaposizione=():void=>
  {
    
    let l1:number=0;
    let l2:number=0;
  
  console.log("setta posizione",this.place?.geometry);
  
    if (this.place && this.place.geometry && this.place.geometry.location && this.place.geometry.location.lng)
    l1=this.place.geometry.location.lng();
    if (this.place && this.place.geometry && this.place.geometry.location && this.place.geometry.location.lat)
      l2=this.place.geometry.location.lat();
  this.posizione={
  type:"Point",
  coordinates:[l1,l2]
  //coordinates:[(this.place?.geometry?.location?.lng())!,(this.place?.geometry?.location?.lat())!]
  }
  
  }*/


  addimmagine = (pid:string,folder: string, name: string, tipo: string): void => {
    this.immagini?.push(new immagine(pid,folder, name, tipo));
  }
  delimmagine = (pid:string): void => {
    let ki:number|undefined=this.immagini?.findIndex((ele)=>ele._id==pid);
    if (ki && ki>=0)
      this.immagini?.splice(ki,1);
  }


  setImmaginePred = (pimm: immagine) => {
    this.immagini?.forEach((ele: immagine) => {
      if (ele == pimm) ele.predefinita = true;
      else ele.predefinita = false;
    }

    )

  }


  getImmaginePred = (): immagine | undefined => {
    let ip: immagine | undefined = this.immagini?.find((el) => el.predefinita == true);
    if (ip == undefined && this.immagini && this.immagini.length > 0)
      ip = this.immagini[0];


    return ip;
  }


  sortimmagini = () => {

    if (this.immagini) {
  let a=    this.immagini.sort((a, b) => {

        if (a.predefinita && !b.predefinita) {
          return -1;
        }
        if (!a.predefinita && b.predefinita) {
          return 1;
        }

        
        return 0;
      }
      )
      this.immagini=a;
    }
    
  }

  constructor() {
    this.codice = "I" + uuid();
    this.prezzo = 0;
    this.indirizzo = "";
    this.descrizione = "";
    this.stato = "01";
    this.proprietario = { cognome: "", nome: "", codicefiscale: "" };
    this.immagini = [];
    this.annoCostruzione = 1980;
    this.pianoImmobile = 0;
    this.pianoStabile = 0;
    this.camere = undefined;
    this.altrestanze = 0;
    this.livelli = undefined;
    this.bagni = 1;
    this.balconi = 0;
    this.ripostiglio = undefined;
    this.ariacondizionata = undefined;
    this.spesecondominiali = 0;
  };

  to_obj = () => {
    if (this.datains && typeof this.datains == 'string') {
      this.datains = new Date(this.datains);
    }
    if (this.dataultmod && typeof this.dataultmod == 'string') {
      this.dataultmod = new Date(this.dataultmod);
    }



  }



}

export default immobile;