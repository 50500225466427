



class immagine
{
_id?:string;    
folder?:string;
name?:string;
tipo?:string;
predefinita?:boolean;

constructor(pid:string,folder:string,name:string,tipo:string)
{
this._id=pid;
this.folder=folder;
this.name=name;
this.tipo=tipo;
this.predefinita=false;
};


}

export default immagine;