
import React, { useEffect, useState, useRef } from 'react';
import '../stili/App.css';
import '../stili/App2.css';
import '../stili/App01.css';
import { Button } from 'primereact/button';



import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

import { Image } from 'primereact/image';
import { DataScroller } from 'primereact/datascroller';

import quadImmobile from '../img/quadImmobile.svg';
import busta from '../img/busta.svg';
import visualizza from '../img/visualizza.svg';
import imageEsempio from '../img/imageEsempio.png';
import { useTranslation } from 'react-i18next';

//firebase 



import ProfiloUtente from '../obj/ProfiloUtente';
import datiGen from '../util/datiGen';

import { useNavigate, useLocation, Link, redirect } from "react-router-dom";
import restImmobile from '../restcall/restImmo';
import { ShowChart } from '@mui/icons-material';

import AWS from 'aws-sdk';
import restProfiloUtente from '../restcall/restProfiloUtente';
import moment from 'moment/min/moment-with-locales';





let deferredPrompt;




const RiepNotifiche = (props) => {
  const [putente, setPutente] = useState();


  const [notif, setNotif] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const ds = useRef(null);

  let timeoutID = -1;


  useEffect(() => {
    console.log("RiepVendita", "useeffect 52", "timeoutID:", timeoutID,props);
    
    getListNotifiche();


  }
    , []);


  useEffect(() => {

    console.log("RiepNotifiche", "useeffect 53", "timeoutID:", timeoutID);

  }, [notif]);


  const getListNotifiche = () => {
    let esci = 0;
        if (datiGen.getInstance().getUtenteCollegato()) {
      esci = 1;

      restProfiloUtente.getInstance().getNotifichebyIdope(datiGen.getInstance().getUtenteCollegato().idutente,props.righe  ).then((kk) => {

setNotif(kk);
        
        if (timeoutID > 0) {
          clearTimeout(timeoutID);
        }

      }).catch((error) => {
    //    console.log("getImmobilibyIdope", "321", error);
      }

      )
    }


    if (esci == 0 && (timeoutID < 0 || timeoutID == 'undefined' || timeoutID === 'undefined')) {

      console.log( "Dentro nuovo timeout tra 500", esci, "timeoutID:", timeoutID);
      
      timeoutID = setTimeout(() => getListNotifiche(), 500);
      console.log( "dopo nuovo timeout ", esci, "timeoutID:", timeoutID);
    }
    else {
      console.log( "no altri timeout tra 500", "esci:", esci, "timeoutID:", timeoutID);
    }


  }

  const seleziona=(data)=>
  {
console.log("Riepimmobile","seleziona data",data);
  //  navigate("/dettImmobile", { state: { funzione: "Edit",immobile:JSON.stringify(data) } });
    
  }


  const downloadfile = (chiave,immo) => {
    /*const S3_BUCKET = "immobili";

    // S3 Region
    const REGION = "r1-it.storage.cloud.it";

    // S3 Credentials
    AWS.config.update({

      accessKeyId: "weveering",
      secretAccessKey: "WeVee@78@",
    });
    const s3 = new AWS.S3({
      endpoint: "r1-it.storage.cloud.it",
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    let itmp = { ...immo };
    let kkey = "immobili/" + itmp.codice + "/" + chiave;
    var params = { Bucket: S3_BUCKET, Key: kkey, Expires: 60 };
    var url = s3.getSignedUrl('getObject', params);
    console.log('The URL is', url); // expires in 60 seconds
    return url;*/
    return "";
  }

  const itemTemplate = (data) => {
    return (
      <>
      <div className='riepContainer' id='akt'{...notif.length ? notif.length.toString() : 123} onClick={()=>{seleziona(data);}} >
      <div style={{}} className='riepdiv left-pad'>

      
              
              <Image src={data.tipo=='Abb'?quadImmobile:imageEsempio} preview height='100' width='100' className='clip'
          style={{ borderWidth: "4px", backgroundColor: '#46c0b5', borderRadius: "10px", padding: "1vh" }} />
            </div>
            <div className='riepdiv' >
              <p className='rieppara'>
             {moment(data.datains).format("L")}&nbsp;{moment(data.datains).format("HH:mm")} <b>{data.titolo}</b><br />
                {data.descrizione}
              </p>
             
              <div >

              </div>
              </div>
            </div>
            </>
    );
    }

    const footer =     <div style={{ marginRight: "2em",marginLeft:"2em" }}>
    <div style={{ display:'flex',flexDirection:'row',justifyContent:props.righe==1?'space-between':'flex-end',marginTop:"1vh" }}>
    
    {props.righe==1 &&
    <Link to={"/listaNotifiche"} className='riepTextButton' >
        <span className='riepTextButton'>{t('HomeUtente.VediTutti')}</span> &nbsp;
        <i className="pi pi-plus-circle riepTextButton" ></i>
      </Link>
}

     

    </div>
  </div>;


  return (

    <div className='RiepExtContainer perscroll tabresp' >
      <p className='riepTitolo'>
        {t('HomeUtente.Notifiche')}
      </p>
{notif.length>0 &&
<>
      <DataScroller value={notif} itemTemplate={itemTemplate} footer={footer} rows={props.righe} buffer={0.4} loader  ref={ds}/>
      </>
}

  
    </div>






  );
}



export default RiepNotifiche;
