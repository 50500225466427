import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import './stili/index.css';
import './stili/App01.css';
import App from './pagine/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createMemoryRouter,
  RouterProvider,
  redirect,
  Outlet,

} from "react-router-dom";
import './i18n';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import DettProfilo from './pagine/DettProfilo';

const SceltaAzione  = React.lazy(() => import( './pagine/SceltaAzione'));
const HomeUtente  = React.lazy(() => import( './pagine/HomeUtente'));
const Vendita  = React.lazy(() => import( './pagine/Vendita'));
const DettImmobile  = React.lazy(() => import( './pagine/DettImmobile'));
const MenuUtente  = React.lazy(() => import( './pagine/MenuUtente'));

// import i18n (needs to be bundled ;))



const ListaImmobili = React.lazy(() => import('./pagine/ListaImmobili'));
const Ordini = React.lazy(() => import('./pagine/OrdinieFatture'));
const Comprare = React.lazy(() => import('./pagine/Comprare'));
const ListaNotifiche = React.lazy(() => import('./pagine/ListaNotifiche'));

const CercaMappa = React.lazy(() => import('./pagine/CercaMappa'));
const ListaImmobiliRicerca = React.lazy(() => import('./pagine/ListaImmobiliRicerca'));
const DatiUtente = React.lazy(() => import("./pagine/DatiUtente"));


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      {
        path: "/datiUtente",
        element:
          <Suspense fallback={<div>Loading...</div>}>
            <DatiUtente />
          </Suspense>


      },
      {
        path: "/sceltaAzione",
        element: <Suspense fallback={<div>Loading...</div>}>
         <SceltaAzione /> </Suspense>,
      },
      {
        path: "/homeUtente",
        element: <Suspense fallback={<div>Loading...</div>}><HomeUtente /> </Suspense>,
      },
      {
        path: "/menuUtente",
        element:<Suspense fallback={<div>Loading...</div>}> <MenuUtente /> </Suspense>,
      },
      {
        path: "/vendita",
        element:<Suspense fallback={<div>Loading...</div>}> <Vendita /> </Suspense>,
      },
      {
        path: "/comprare",
        element:<Suspense fallback={<div>Loading...</div>}> <Comprare /> </Suspense>,
      },
      {
        path: "/cercaMappa",
        element:<Suspense fallback={<div>Loading...</div>}> <CercaMappa /> </Suspense>,
      },
      {
        path: "/dettImmobile",
        element:<Suspense fallback={<div>Loading...</div>}> <DettImmobile /> </Suspense>,
      },
      {
        path: "/listaImmobili",
        element:<Suspense fallback={<div>Loading...</div>}> <ListaImmobili /> </Suspense>,
      },
      {
        path: "/listaImmobiliRicerca",
        element:<Suspense fallback={<div>Loading...</div>}> <ListaImmobiliRicerca /> </Suspense>,
      },
      {
        path: "/ordini",
        element: <Suspense fallback={<div>Loading...</div>}><Ordini /> </Suspense>,
      },
      /* {
         path: "/EsitoPagamentoOK",
         element: <App />,
         
       },
       {
         path: "/EsitoPagamentoHome",
         element: <App />,
       },*/
      {
        path: "/listaNotifiche",
        element:<Suspense fallback={<div>Loading...</div>}> <ListaNotifiche /> </Suspense>,
      },
      {
        path: "/dettProfilo",
        element:<Suspense fallback={<div>Loading...</div>}> <DettProfilo /> </Suspense>,
      }
    ]
  }
]);
root.render(
  <React.StrictMode>
    <APIProvider apiKey="AIzaSyCSZNdJjUfJnWz_6dsfo8eOCzeVHYsHoZQ">
      <RouterProvider router={router} />
    </APIProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


